import { HttpLink, InMemoryCache, ApolloClient } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import fetch from 'isomorphic-fetch';

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV;
const DEV = activeEnv === 'development';
const GRAPHQL_SERVER_LOCAL = 'http://localhost:4000/graphql';
const GRAPHQL_SERVER_PROD = 'https://api.workahead.io/graphql';
const GRAPHQL_SERVER = DEV ? GRAPHQL_SERVER_LOCAL : GRAPHQL_SERVER_PROD;

const httpLink = new HttpLink({ uri: GRAPHQL_SERVER, fetch });
const persistedQueriesLink = createPersistedQueryLink({ sha256 });
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: persistedQueriesLink.concat(httpLink),
});

export default client;