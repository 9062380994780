import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
});

const theme = {
  colors: {
    primary: 'orange',
    bg: '#fdfdfd',
    bad: 'red',
    ok: 'orange',
    good: 'green',
  },
  styles: {
    global: {
      html: {
        fontSize: {
          base: 'sm',
          md: 'md',
        },
      },
      h1: {
        fontWeight: '100!important',
        // fontSize: '6xl',
      },
      // h2: {
      //   fontSize: '3xl',
      // },
      // h3: {
      //   fontSize: '2xl',
      // },
      // h4: {
      //   fontSize: '6xl',
      // }
    },
  },
  components: {
    Text: {
      baseStyle: {
        lineHeight: 1.3,
        '& > a': {
          color: 'red.600',
        },
      },
    },
  },
  breakpoints,
  // fonts: {
  //   body: 'system-ui, sans-serif',
  //   heading: 'Work sans, system-ui, sans-serif',
  //   mono: 'monospace',
  // },
};

export default extendTheme(theme);
